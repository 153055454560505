import { useMutation } from '@apollo/client';
import {
  AutoDelete,
  Clear,
  ConnectWithoutContact,
  Delete,
  DoneAll,
  FileCopy,
  KeyboardArrowDown,
  Link,
  LinkedIn,
  RemoveDone,
  Save,
  Schedule,
  Send,
  SupervisorAccount,
  Undo,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Divider, Menu, Stack, Tooltip, Typography, IconButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { useContext, useState } from 'react';
import { PostLifecycleState, PostType, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { ENABLE_SHARE_LINK } from '../../graphql/mutations';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import DatePicker from '../DatePicker/DatePicker';
import ConfirmationPostModal from './ConfirmationPostModal';
import { Message } from './PostEditor';
import RequestApprovalModal from './RequestApprovalModal';
import { useTranslation } from 'react-i18next';
import { SET_POST_VISIBILITY } from '../../graphql/mutations';

interface Props {
  setError: ({ type, text, action }: Message) => void;
  onClose: (force?: boolean) => void;
  openChat: boolean;
  isLink?: boolean;
  isLinkInbox?: boolean;
}

const styleBoxMobile = {
  marginTop: 5,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  width: '100%',
  backgroundColor: 'background.paper',
};

const CustomMenuItem = ({
  onClick,
  icon,
  text,
  disabled = false,
  textInfo,
  connectAccount = false,
  textConnectAccount,
  postPermission = false,
  textPostPermission,
  isLinkInbox = false,
  isLink = false,
}: {
  onClick: () => void;
  icon?: any;
  text: string;
  disabled?: boolean;
  textInfo?: string;
  connectAccount?: boolean;
  textConnectAccount?: string;
  postPermission?: boolean;
  textPostPermission?: string;
  isLinkInbox?: boolean;
  isLink?: boolean;
}) => (
  <MenuItem
    disabled={disabled}
    onClick={onClick}
    sx={{
      paddingLeft: 2,
      fontSize: '14px',
      '&.Mui-disabled': {
        backgroundColor: '#EDEDED',
        color: '#00000061',
        opacity: 1,
        pointerEvents: 'none',
      },
      '&:hover': {
        backgroundColor: '#FF007A14',
        '& .text': {
          fontWeight: 'bold',
        },
      },
    }}
  >
    {icon && (
      <ListItemIcon sx={{ minWidth: 40, opacity: disabled ? 0.5 : 1 }}>
        {icon}
      </ListItemIcon>
    )}
    <Stack>
      <Typography
        variant="body2"
        className="text"
        color={disabled ? '#00000061' : 'rgba(0, 0, 0, 0.87)'}
      >
        {text}
      </Typography>
      {!postPermission && textPostPermission && !isLinkInbox && !isLink ? (
        <Typography
          color="#D32F2F"
          fontSize="12px"
          fontWeight={500}
          sx={{ whiteSpace: 'pre-line', lineHeight: '1.2' }}
        >
          {textPostPermission}
        </Typography>
      ) : textInfo ? (
        <Typography
          color={'rgba(0, 0, 0, 0.6)'}
          fontSize="11px"
          sx={{ whiteSpace: 'pre-line', lineHeight: '1.2' }}
        >
          {textInfo}
        </Typography>
      ) : null}
      {connectAccount && (
        <Typography
          color="#D32F2F"
          fontSize="12px"
          fontWeight={500}
          sx={{ whiteSpace: 'pre-line', lineHeight: '1.2' }}
        >
          {textConnectAccount}
        </Typography>
      )}
    </Stack>
  </MenuItem>
);

const PostActions = ({ setError, onClose, openChat, isLink, isLinkInbox }: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { isMobile } = useContext(DeviceContext);
  const { postState } = useContext(PostContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [datePickerScheduleOpen, setDatePickerScheduleOpen] = useState<boolean>(false);
  const [unpublishModalOpen, setUnpublishModelOpen] = useState<boolean>(false);
  const [deletePostModalOpen, setDeletePostModalOpen] = useState<boolean>(false);
  // const [savingStatus, setSavingStatus] = useState<string>('Syncing...');
  const [enableShareLink] = useMutation(ENABLE_SHARE_LINK);
  const { post, status, handlers, postRef, setHidePost, hidePost } = postState ?? {};
  const open =
    Boolean(anchorEl) &&
    !datePickerScheduleOpen &&
    !status?.confirmationPostsDialogOpen &&
    !status?.requestApprovalDialogOpen;

  const postScheduleDefaultDate = new Date(post?.schedule ? post?.schedule : Date.now());
  postScheduleDefaultDate.setHours(12, 0, 0, 0);
  const accountExpired =
    !!post?.brand?.account && new Date(post?.brand?.account?.exp) < new Date();

  const [setPostVisibility, { loading }] = useMutation(SET_POST_VISIBILITY, {
    onCompleted: (data) => {
      if (data.setPostVisibility?.success) {
        const isHidden = !!data.setPostVisibility.visibility;
        setSuccessMessage(
          isHidden
            ? t('The post has been successfully hidden.')
            : t('The post is now visible to everyone.'),
        );
        handlers?.handleVisibilityChange(!!data.setPostVisibility.visibility);
        return;
      }
      setErrorMessage('Failed to update post visibility');
    },
  });

  const handleVisibility = () => {
    if (status?.isNew && setHidePost) {
      setHidePost(!hidePost);
    } else {
      setPostVisibility({
        variables: {
          postId: postState?.post?._id || '',
          isHidden: !postState?.post?.hidePost,
        },
      });
    }
  };

  const closeEditor = (force: boolean = false) => {
    if (!user) {
      handleCloseActionMenu();
      return;
    }
    onClose(force);
  };

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const handleSchedule = () => {
    setDatePickerScheduleOpen(true);
  };

  const handleRemoveSchedule = () => {
    handlers?.handleRemoveSchedule();
    handleCloseActionMenu();
  };

  const handleSubmitButton = () => {
    handlers?.handleSaveAsDraft({
      callback: () => {
        // handlers?.handlePostOnLinkedin();
      },
    });

    if (post?.type === PostType.Insights) {
      handlers?.handlePostOnLinkedin();
      return;
    }
    handlers?.handleOpenConfirmationPostsDialog();
  };

  // const handle

  const handleSchedulePostSubmit = (date: Date) => {
    handlers?.handleScheduleChange(date, () => {
      setSuccessMessage(t('Post scheduled successfully 🎉'));
    });

    setDatePickerScheduleOpen(false);
    handleCloseActionMenu();
  };

  const handleUnpublishPost = () => {
    handlers?.handleUnpublishLinkedinPost();
    setUnpublishModelOpen(false);
    handleCloseActionMenu();
  };

  const handleCloseModalDelete = () => {
    setUnpublishModelOpen(false);
  };

  const handleViewOnLinkedin = () => {
    window.open(
      `https://www.linkedin.com/feed/update/${post?.linkedin?.postUrn}`,
      '_blank',
      'noreferrer',
    );

    handleCloseActionMenu();
  };

  const handleUnpublishLinkedin = () => {
    setUnpublishModelOpen(true);
    handleCloseActionMenu();
  };

  const handleCreateAdvocacyPost = () => {
    handlers?.handleCreateAdvocacy(() => {
      setSuccessMessage(t('Post created successfully'));
      onClose(true);
    });
    handleCloseActionMenu();
  };

  const handleUpdateAdvocacyPost = () => {
    handlers?.handleUpdateAdvocacy(() => {
      setSuccessMessage(t('Post updated successfully'));
      onClose(true);
      closeEditor();
    });
    handleCloseActionMenu();
  };

  const handleSaveAsDraft = (close: boolean = false) => {
    handlers?.handleSaveAsDraft({
      callback: () => {
        setSuccessMessage(t('The post has been saved as a draft.'));
        if (close) closeEditor(true);
      },
    });
  };

  const handleSendToContentBoard = () => {
    handleCloseActionMenu();

    handlers?.handleSaveAsDraft({
      callback: () => {
        handlers?.handleStateChange(PostLifecycleState.Ready);
        closeEditor(true);
        setSuccessMessage(t('The post has been submitted to the user content board.'));
      },
      lifecycleState: PostLifecycleState.Ready,
    });
  };

  const handleTurnIntoAdvocacy = () => {
    handlers?.handleTurnIntoAdvocacy();
    handleCloseActionMenu();
  };

  const handleCloseDatepicker = () => {
    setDatePickerScheduleOpen(false);
  };

  const handleCopyLink = () => {
    handlers?.handleSaveAsDraft({});
    if (!post || !post._id) {
      setError({
        type: 'error',
        text: t('Post cannot be shared until it has been saved'),
      });
      return;
    }

    navigator.clipboard.writeText(`${window.location.origin}/post/${post._id}`);
    setSuccessMessage(t('Post link copied to your clipboard'));
    handleCloseActionMenu();

    if (!post.shareLink || !post.shareLink.share) {
      enableShareLink({
        variables: {
          postId: post._id,
        },
      });
    }
  };

  const handleRevertApproval = () => {
    handleCloseActionMenu(); // Close the menu

    if (status?.isScheduled) {
      handlers?.handleRemoveSchedule(() => {
        handlers?.handleSaveAsDraft({
          callback: () => {
            handlers?.handleStateChange(PostLifecycleState.PendingApproval); // Change to PendingApproval
            setSuccessMessage(t('The post has been reverted to Pending Approval.')); // Success message
          },
          lifecycleState: PostLifecycleState.PendingApproval, // Save with PendingApproval lifecycle state
        });
      });
      return;
    }

    handlers?.handleSaveAsDraft({
      callback: () => {
        handlers?.handleStateChange(PostLifecycleState.PendingApproval); // Change to PendingApproval
        setSuccessMessage(t('The post has been reverted to Pending Approval.')); // Success message
      },
      lifecycleState: PostLifecycleState.PendingApproval, // Save with PendingApproval lifecycle state
    });
  };

  const handleApprove = () => {
    handleCloseActionMenu(); // Close the menu

    handlers?.handleSaveAsDraft({
      callback: () => {
        handlers?.handleStateChange(PostLifecycleState.Approved); // Change to approved state
        setSuccessMessage(t('The post has been approved')); // Show success message
        closeEditor(true); // Close the editor
      },
      lifecycleState: PostLifecycleState.Approved, // Save with Approved lifecycle state
    });
  };

  const handleDiscard = () => {
    handleCloseActionMenu(); // Close the menu

    handlers?.handleSaveAsDraft({
      callback: () => {
        handlers?.handleStateChange(PostLifecycleState.Discarded); // Change to Discarded state
        setSuccessMessage(t('The post has been discarded')); // Success message
        closeEditor(true); // Close the editor
      },
      lifecycleState: PostLifecycleState.Discarded, // Save with Discarded lifecycle state
    });
  };

  const handleReturnToDraft = () => {
    handleCloseActionMenu(); // Close the menu

    handlers?.handleSaveAsDraft({
      callback: () => {
        handlers?.handleStateChange(PostLifecycleState.Draft); // Change to draft state
        setSuccessMessage(t('The post has been returned to draft')); // Show success message
      },
      lifecycleState: PostLifecycleState.Draft, // Save with Draft lifecycle state
    });
  };

  const handleDeletePost = () => {
    handleCloseActionMenu();
    handlers?.handleDeletePost(() => closeEditor(true));
  };

  const handleRequestApproval = () => {
    handlers?.handleSaveAsDraft({
      callback: () => {
        handlers?.handleOpenRequestApprovalDialog();
      },
      lifecycleState: PostLifecycleState.PendingApproval,
    });

    handlers?.handleStateChange(PostLifecycleState.PendingApproval);
    handlers?.handleOpenRequestApprovalDialog();
  };

  const handleDuplicatePost = () => {
    handlers?.handleDuplicate(() => {
      setSuccessMessage('The post has been duplicated and is now available as a draft.');
    });
    handleCloseActionMenu();
  };

  const unpublishLinkedinPost = status?.isPosted;
  const viewOnLinkedIn = status?.isPosted;
  const convertToAdvocacyPost =
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isScheduled &&
    user?.role.includes(UserRole.AdvocacyManager);

  const userAssignated =
    !user?.role.includes(UserRole.BrandManager) &&
    !user?.role.includes(UserRole.LimitedBrandManager);

  const postOnLinkedin =
    (!isLink && !status?.isAdvocacyParent && !status?.isPosted) ||
    (isLink &&
      post?.lifecycleState !== PostLifecycleState.Discarded &&
      post?.lifecycleState !== PostLifecycleState.PendingApproval);

  const saveAsDraft =
    (!status?.isPosted &&
      !status?.isAdvocacyParent &&
      (status?.isNew ||
        (!status?.isNew && post?.lifecycleState === PostLifecycleState.Draft))) ||
    (post?.lifecycleState === PostLifecycleState.Error && userAssignated);

  const savePost =
    (!status?.isPosted && !status?.isAdvocacyParent) ||
    (post?.lifecycleState === PostLifecycleState.Error && userAssignated);

  const returnToDraft =
    !isLink &&
    !userAssignated &&
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isAdvocacy &&
    post?.lifecycleState &&
    [
      PostLifecycleState.Ready,
      PostLifecycleState.Approved,
      PostLifecycleState.Discarded,
      PostLifecycleState.PendingApproval,
      PostLifecycleState.Error,
    ].includes(post?.lifecycleState);

  const sendTo =
    !isLink &&
    !status?.isAdvocacyParent &&
    post?.brandId !== user?.brandId &&
    post?.lifecycleState &&
    [PostLifecycleState.Draft, PostLifecycleState.Error].includes(post?.lifecycleState);

  const baseSchedule = !status?.isPosted && !status?.isAdvocacyParent;

  const schedule = baseSchedule && post?.lifecycleState !== PostLifecycleState.Scheduled;

  const removeSchedule =
    baseSchedule && post?.lifecycleState === PostLifecycleState.Scheduled;

  const createAdvocacy =
    !status?.isPosted &&
    status?.isAdvocacyParent &&
    status.isNew &&
    user?.role.includes(UserRole.AdvocacyManager);

  const updateAdvocacy =
    !status?.isPosted &&
    status?.isAdvocacyParent &&
    !status.isNew &&
    user?.role.includes(UserRole.AdvocacyManager);

  const copyLink =
    !status?.isPosted && !status?.isAdvocacyParent && !status?.isNew && !isLink;

  const requestApproval =
    !isLink &&
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isScheduled &&
    post?.lifecycleState !== PostLifecycleState.Approved &&
    post?.lifecycleState !== PostLifecycleState.PendingApproval;

  const deletePost =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    !isLink &&
    !status?.isNew &&
    [
      PostLifecycleState.Draft,
      PostLifecycleState.Discarded,
      PostLifecycleState.Error,
    ].includes(post?.lifecycleState);

  const revertApproval =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    [PostLifecycleState.Approved, PostLifecycleState.Scheduled].includes(
      post?.lifecycleState,
    );

  const approve =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    [PostLifecycleState.Discarded, PostLifecycleState.PendingApproval].includes(
      post?.lifecycleState,
    );

  const discard =
    (isLink &&
      post?.lifecycleState &&
      [PostLifecycleState.Approved, PostLifecycleState.PendingApproval].includes(
        post?.lifecycleState,
      )) ||
    (!isLink &&
      !status?.isAdvocacyParent &&
      post?.lifecycleState &&
      [
        PostLifecycleState.Ready,
        PostLifecycleState.Approved,
        PostLifecycleState.PendingApproval,
        PostLifecycleState.Error,
      ].includes(post?.lifecycleState));

  const duplicate = !status?.isAdvocacyParent && !status?.isNew && !isLink;

  // const linkPermission = !isLink || (isLink && status?.isEditDisabled && !status.isCurrentUserEditing)

  let actionButtonText = t('Actions');
  if (status?.isPosting) actionButtonText = t('Posting...');
  else if (status?.isUnposting) actionButtonText = t('Unpublishing...');

  const menuItems: {
    onClick: () => void;
    icon?: JSX.Element;
    text: string;
    disabled?: boolean;
    infoText?: string;
    connectAccount?: boolean;
    textConnectAccount?: string;
    postPermission?: boolean;
    textPostPermission?: string;
  }[] = [];

  if (
    postOnLinkedin &&
    (isLink
      ? post?.lifecycleState !== PostLifecycleState.Posted
      : post?.lifecycleState !== PostLifecycleState.Posted) &&
    !(
      isLinkInbox &&
      (post?.lifecycleState === PostLifecycleState.PendingApproval ||
        post?.lifecycleState === PostLifecycleState.Discarded)
    )
  ) {
    menuItems.push({
      onClick: handleSubmitButton,
      icon: <LinkedIn sx={{ color: '#0963CB' }} fontSize="small" />,
      text: t('Post now'),
      disabled:
        status?.isSubmitDisabled ||
        !postRef?.current?.content?.body || // Verifica si body existe
        postRef.current.content.body.replace(/<[^>]*>?/gm, '').length === 0 ||
        postRef.current.content.body.replace(/<[^>]*>?/gm, '').length > 3000 ||
        !post?.brand?.account ||
        accountExpired,
      infoText: t('Publish the post now on LinkedIn'),
      connectAccount:
        !post?.brand?.account ||
        accountExpired ||
        (!!postRef?.current?.content?.body &&
          postRef?.current?.content?.body?.replace(/<[^>]*>?/gm, '').length > 3000),
      textConnectAccount: accountExpired
        ? t('Reconnect your LinkedIn to publish') // Condición 1: La cuenta está expirada
        : !post?.brand?.account
        ? t('Connect your LinkedIn to publish') // Condición 2: No hay una cuenta conectada
        : !!postRef?.current?.content?.body &&
          postRef?.current?.content?.body?.replace(/<[^>]*>?/gm, '').length > 3000
        ? t('The post exceeds the maximum allowed length')
        : '',
      postPermission:
        user?.role?.includes(UserRole.User) &&
        !user?.role?.includes(UserRole.LimitedBrandManager) &&
        !user?.role?.includes(UserRole.BrandManager)
          ? true // If user has the "User" role without "LimitedBrandManager" or "BrandManager"
          : user?.role?.includes(UserRole.PostForOthers), // Otherwise, rely on "PostForOthers" role
      textPostPermission: t('You dont have permission'),
    });
  }

  if (
    schedule &&
    !(
      post?.lifecycleState === PostLifecycleState.PendingApproval &&
      (isLinkInbox || isLink)
    ) &&
    !(post?.lifecycleState === PostLifecycleState.Discarded && (isLinkInbox || isLink))
  ) {
    menuItems.push({
      onClick: handleSchedule,
      icon: <Schedule sx={{ color: 'primary.main' }} fontSize="small" />,
      text: t('Schedule'),
      disabled:
        status?.isSubmitDisabled ||
        !postRef?.current?.content?.body || // Verifica si body existe
        postRef?.current?.content?.body?.replace(/<[^>]*>?/gm, '').length === 0 ||
        postRef?.current?.content?.body?.replace(/<[^>]*>?/gm, '').length > 3000 ||
        !post?.brand?.account ||
        accountExpired,
      infoText: t('Publish post on scheduled date'),
      connectAccount:
        !post?.brand?.account ||
        accountExpired ||
        (!!postRef?.current?.content?.body &&
          postRef?.current?.content?.body?.replace(/<[^>]*>?/gm, '').length > 3000),
      textConnectAccount:
        accountExpired && post
          ? t('Reconnect your LinkedIn to schedule')
          : !post?.brand?.account && post
          ? t('Connect your LinkedIn to schedule')
          : !!postRef?.current?.content?.body &&
            postRef?.current?.content?.body?.replace(/<[^>]*>?/gm, '').length > 3000
          ? t('The post exceeds the maximum allowed length')
          : '',

      postPermission:
        user?.role?.includes(UserRole.User) &&
        !user?.role?.includes(UserRole.LimitedBrandManager) &&
        !user?.role?.includes(UserRole.BrandManager)
          ? true // If user has the "User" role without "LimitedBrandManager" or "BrandManager"
          : user?.role?.includes(UserRole.PostForOthers), // Otherwise, rely on "PostForOthers" role
      textPostPermission: t('You dont have permission'),
    });
  }

  if (requestApproval && !isLinkInbox) {
    menuItems.push({
      onClick: handleRequestApproval,
      icon: <SupervisorAccount fontSize="small" />,
      text: t('Request approval'),
      disabled: isLinkInbox,
      infoText: t('Ask the user to approve the post'),
    });
  }

  if (sendTo && !isLinkInbox) {
    menuItems.push({
      onClick: handleSendToContentBoard,
      icon: <Send sx={{ color: '#8DC391' }} fontSize="small" />,
      text: t('Send'),
      disabled: !status?.isSavable,
      infoText: t('Deliver this post to the final user as ready to be posted'),
    });
  }

  if (removeSchedule) {
    menuItems.push({
      onClick: handleRemoveSchedule,
      icon: <AutoDelete sx={{ color: 'primary.main' }} fontSize="small" />,
      text: t('Remove Schedule'),
      infoText: t('Cancel schedule and continue editing'),
    });
  }

  if (revertApproval) {
    menuItems.push({
      onClick: handleRevertApproval,
      icon: <RemoveDone fontSize="small" />,
      text: t('Revert Approval'),
      infoText: t('Disapprove and notify the creator'),
    });
  }

  if (approve) {
    menuItems.push({
      onClick: handleApprove,
      icon: <DoneAll fontSize="small" />,
      text: t('Approve'),
      infoText: t('Approve and notify the creator'),
    });
  }

  if (discard && !(isLinkInbox && post?.lifecycleState === PostLifecycleState.Ready)) {
    menuItems.push({
      onClick: handleDiscard,
      icon: <Clear fontSize="small" />,
      text: t('Discard'),
      infoText: t('Discard this post'),
    });
  }

  if (saveAsDraft && !isLinkInbox && !isLink) {
    menuItems.push({
      onClick: () => handleSaveAsDraft(true),
      icon: <Save fontSize="small" />,
      text: t('Save as Draft'),
      disabled: !status?.isSavable,
      infoText: t('Save as a draft and publish later'),
    });
  }

  if (returnToDraft && !isLinkInbox) {
    menuItems.push({
      onClick: handleReturnToDraft,
      icon: <Undo fontSize="small" />,
      text: t('Return to Draft'),
      infoText: t('Save as a draft and publish later'),
    });
  }

  if (duplicate && !isLinkInbox) {
    menuItems.push({
      onClick: handleDuplicatePost,
      icon: (
        <FileCopy
          fontSize="small"
          sx={{
            marginLeft: { xs: 0, md: status?.isPosted ? -1 : 0 },
          }}
        />
      ),
      text: isMobile || !status?.isPosted ? t('Create copy') : '',
      infoText: t('Create and open a copy of this post'),
      disabled: isLinkInbox,
    });
  }

  if (copyLink && !isLinkInbox) {
    menuItems.push({
      onClick: handleCopyLink,
      icon: <Link fontSize="small" />,
      text: t('Share via Link'),
      disabled: isLinkInbox,
    });
  }

  if (convertToAdvocacyPost && !isLinkInbox && !isLink) {
    menuItems.push({
      onClick: handleTurnIntoAdvocacy,
      icon: <ConnectWithoutContact fontSize="small" />,
      text: t('Create Advocacy Post'),
    });
  }

  if (createAdvocacy && !isLinkInbox) {
    menuItems.push({
      onClick: handleCreateAdvocacyPost,
      text: t('Create'),
    });
  }

  if (updateAdvocacy && !isLinkInbox) {
    menuItems.push({
      onClick: handleUpdateAdvocacyPost,
      text: t('Update'),
    });
  }

  menuItems.reverse();

  return (
    <>
      <ConfirmationPostModal
        status={status}
        handlers={handlers}
        post={post}
        onClose={closeEditor}
      />

      <RequestApprovalModal
        status={status}
        handlers={handlers}
        onClose={closeEditor}
        handleCopyLink={handleCopyLink}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseActionMenu}
        sx={{
          transform: 'translateY(-10px)',
        }}
        PaperProps={{
          sx: {
            mb: 1,
            borderRadius: '8px',
            boxShadow:
              '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
            width: { xs: '90%', md: '280px' },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item, index) => (
          <CustomMenuItem
            key={index}
            onClick={item.onClick}
            icon={item.icon}
            text={item.text}
            disabled={item.disabled}
            textInfo={item.infoText}
            connectAccount={item.connectAccount}
            textConnectAccount={item.textConnectAccount}
            postPermission={item.postPermission}
            textPostPermission={item.textPostPermission}
            isLinkInbox={isLinkInbox}
            isLink={isLink}
          />
        ))}
      </Menu>
      {datePickerScheduleOpen && (
        <DatePicker
          date={postScheduleDefaultDate}
          open={datePickerScheduleOpen}
          maxDate={new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) /* 30 days */}
          anchorElement={anchorEl}
          onClose={handleCloseDatepicker}
          onSubmit={handleSchedulePostSubmit}
          primaryActionTitle={datePickerScheduleOpen ? 'Schedule' : 'Save'}
          isMobile={isMobile}
        />
      )}
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : deletePost ? 'space-between' : 'end'}
        spacing={1}
        sx={isMobile ? styleBoxMobile : {}}
      >
        {deletePost && !isMobile && !isLink && !isLinkInbox && (
          <Tooltip title="Delete post" placement="top">
            <Delete
              onClick={() => setDeletePostModalOpen(true)}
              sx={{
                cursor: 'pointer',
                color: '#D32F2F',
                marginY: 'auto',
              }}
            />
          </Tooltip>
        )}

        <Divider />
        <Stack
          sx={{
            paddingX: { xs: 2, sm: 0 },
            paddingBottom: { xs: 2, sm: 0 },
            paddingTop: { xs: 1, sm: 0 },
          }}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
          gap={{
            xs: 1,
            md: 1,
          }}
        >
          {menuItems.length > 0 && (
            <>
              {menuItems.length > 1 ? (
                <>
                  {deletePost && isMobile && (
                    <LoadingButton
                      onClick={() => setDeletePostModalOpen(true)}
                      variant="outlined"
                      startIcon={<Delete />}
                      loadingPosition="end"
                      fullWidth
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '168.33px',
                          border: 'none',
                          color: '#D32F2F',
                          paddingX: 0,
                          backgroundColor: '#ececec',
                          '&:hover': {
                            backgroundColor: '#d2d2d2',
                            border: 'none',
                          },
                        },
                      }}
                    >
                      <span>Delete post</span>
                    </LoadingButton>
                  )}

                  {postState?.post?.lifecycleState === PostLifecycleState.Draft &&
                    (user?.role.includes(UserRole.BrandManager) ||
                      user?.role.includes(UserRole.LimitedBrandManager)) && (
                      <Tooltip
                        title={
                          (status?.isNew ? hidePost : postState?.post?.hidePost)
                            ? t('Hidden post: Only Brand Managers can see it.')
                            : t('Post visible: to everyone.')
                        }
                        placement="top"
                      >
                        <IconButton
                          onClick={handleVisibility}
                          data-testid="delete-button"
                          disabled={loading}
                          sx={{
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                              backgroundColor: '#e0e0e0',
                            },
                          }}
                        >
                          {status?.isNew ? (
                            hidePost ? ( // For new posts, use local hidePost state
                              <VisibilityOff />
                            ) : (
                              <Visibility sx={{ color: '#E91E63' }} />
                            )
                          ) : postState?.post?.hidePost ? ( // For existing posts, use postState?.post?.hidePost
                            <VisibilityOff />
                          ) : (
                            <Visibility sx={{ color: '#E91E63' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}

                  {savePost && (
                    <LoadingButton
                      onClick={() => handleSaveAsDraft(false)}
                      variant="outlined"
                      loading={
                        status?.isPosting || status?.isUnposting || status?.isSaving
                      }
                      disabled={
                        status?.isPosting || status?.isUnposting || status?.isSaving
                      }
                      fullWidth
                      sx={{
                        width: { xs: '100%', sm: openChat ? '143.4px' : '168.33px' },
                      }}
                    >
                      <span>{t('Save changes')}</span>
                    </LoadingButton>
                  )}
                  <LoadingButton
                    onClick={handleOpenActionMenu}
                    variant="linkedin"
                    endIcon={<KeyboardArrowDown />}
                    loading={status?.isPosting || status?.isUnposting || status?.isSaving}
                    loadingPosition="end"
                    disabled={
                      status?.isPosting || status?.isUnposting || status?.isSaving
                    }
                    fullWidth
                    sx={{
                      width: { xs: '100%', sm: openChat ? '143.4px' : '168.33px' },
                    }}
                  >
                    <span>{actionButtonText}</span>
                  </LoadingButton>
                </>
              ) : duplicate && !isMobile && !isLinkInbox ? (
                <Tooltip title={menuItems[0].infoText} placement="top">
                  <FileCopy
                    fontSize="small"
                    onClick={menuItems[0].onClick}
                    sx={{
                      marginLeft: { xs: 0, md: status?.isPosted ? -1 : 0 },
                      cursor: 'pointer',
                      color: '#757575',
                      marginY: 'auto',
                    }}
                  />
                </Tooltip>
              ) : (
                <LoadingButton
                  onClick={menuItems[0].onClick}
                  variant="outlined"
                  endIcon={menuItems[0].icon}
                  loading={status?.isPosting || status?.isUnposting}
                  loadingPosition="end"
                  disabled={status?.isPosting || status?.isUnposting}
                  sx={{
                    ...(duplicate && {
                      border: 'none',
                      marginRight: { xs: 0, sm: -2 },
                      paddingX: 0,
                      width: 'auto',
                      textDecoration: 'underline',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: 'primary.main',
                        border: 'none',
                      },
                    }),
                  }}
                >
                  {menuItems[0].text}
                </LoadingButton>
              )}
            </>
          )}
          {unpublishLinkedinPost && !isLink && (
            <LoadingButton
              onClick={handleUnpublishLinkedin}
              variant="outlined"
              loading={status?.isUnposting}
              disabled={status?.isUnposting}
            >
              {t('Unpublish LinkedIn post')}
            </LoadingButton>
          )}
          {viewOnLinkedIn && (
            <LoadingButton
              variant="linkedin"
              onClick={handleViewOnLinkedin}
              sx={{
                width: { xs: '100%', sm: openChat ? '143.4px' : '168.33px' },
                whiteSpace: 'nowrap',
              }}
            >
              {t('View on LinkedIn')}
            </LoadingButton>
          )}
        </Stack>
      </Stack>
      <ConfirmationDialog
        title={t('Unpublish post from LinkedIn?')}
        content={
          <>
            {t(
              'This action will remove the post from LinkedIn and reset its status to ‘ready’. This means you will permanently lose all the current likes, comments, views and other data associated with this post.',
            )}
            <br />
            <br />
            <b>{t('Are you sure you want to continue?')}</b>
          </>
        }
        deleteButton
        onConfirm={handleUnpublishPost}
        open={unpublishModalOpen}
        onClose={handleCloseModalDelete}
        okText={t('Unpublish')}
        cancelText={t('Cancel')}
      />
      <ConfirmationDialog
        title={t('Delete post forever?')}
        content={
          <>
            {t('This action cannot be undone and will delete your post forever')}
            <br />
            <br />
            <b>{t('Do you still want to continue?')}</b>
          </>
        }
        deleteButton
        onConfirm={handleDeletePost}
        open={deletePostModalOpen}
        onClose={() => setDeletePostModalOpen(false)}
        okText={t('Delete')}
        cancelText={t('Cancel')}
      />
    </>
  );
};

export default PostActions;
